<script>


import appConfig from "@/app.config";
import helper from "@/assets/helpers/helper";

export default {
  page: {
    title: "ScoreBoard",
    meta: [{name: "description", content: appConfig.description}]
  },
  name: 'ScoreBoardCompetition',
  data() {
    return {
      teams: [],
      name: '',
      state: '',
      title: "ScoreBoard Competition NameComp",
      none: 'none',
      currentSort: 'score',
      currentSortDir: 'descending',
      teams_name: [],
      solves: [],
      team_evolution: {
        series: [],
        chartOptions: {
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'smooth',
            width: 3,
          },
          colors: ['#556ee6', '#34c38f','#ff6600','#66ff33','#E91E63','#9C27B0','#ffff00','#800000','#0000cc','#00ffff'],
          labels: [],
          xaxis: {
            type: 'datetime',

            labels: {
              show: true,
              format: 'MMM dd HH:mm',

            },
          },
          grid: {
            borderColor: '#f1f1f1',
          },
          tooltip: {
            x: {
              format: 'dd/MM/yy HH:mm'
            },
          }
        }
      },
      load1: false,
      load2: false,


    };
  },

  created() {
    this.loadScoreboard(1)
    this.loadEvolution()
  },
  watch: {
    'solves': function(){
      this.parseEvolutionData();
    },
    'state':function(){
      if(this.state == 'running') {
        var channel = this.$store.state.pusher_crisis.subscribe('private-competitions_' + this.$route.params.id);
        channel.bind('competition_challengeSolved', (data) => {
          console.log("scoreboad updated : ", data);
          let index = this.teams.findIndex(item => item.team_id === data.team_id);
          console.log("index = ", index)
          this.teams[index].rank = data.rank
          this.teams[index].score = data.score
          this.teams[index].solves_count += 1
          let item = {
            'team_id': data.team_id,
            'team': this.teams[index].team,
            'value': data.value,
            'created_at': data.created_at
          }
          this.solves.push(item)
          this.$forceUpdate()
        });
      }
    }
  },
  methods: {
    async loadScoreboard() {
      let teams = await helper.getAll('competitions/' + this.$route.params.id + '/scoreboard');
      this.teams = teams.map(p => ({
        ...p,
        score: p.score ? parseInt(p.score) : 0,
        rank: p.ranks.length ? p.ranks[0].rank : '-'
      }))
      this.load1 = true
    },
    async loadEvolution() {
      let evolution = await helper.getAll('competitions/' + this.$route.params.id + '/teams/evolution');
      this.name = evolution.data.name
      this.state = evolution.data.state
      if (evolution.data.teams_name.length) this.load2 = true
      this.begin_at = evolution.data.begin_comp
      this.teams_name = evolution.data.teams_name;
      this.solves = evolution.data.solves;
    },
    parseEvolutionData(){
      let teams = {};
      let label_x = [this.begin_at];
      var i, j;
      for (i = 0; i < this.teams_name.length; i++) {
        teams[this.teams_name[i]] = [0];
      }

      for (i = 0; i < this.solves.length; i++) {

        label_x.push(this.solves[i].created_at);
        let value = this.solves[i].value;
        let team_solvator = this.solves[i].team.name;
        for (j = 0; j < this.teams_name.length; j++) {
          if (team_solvator === this.teams_name[j])   // If We Are in the Team that solved The Challenge
          {
            teams[this.teams_name[j]].push(teams[this.teams_name[j]].slice(-1)[0] + value);
          } else // If We are in a Team that not solved the Challenge
          {
            teams[this.teams_name[j]].push(teams[this.teams_name[j]].slice(-1)[0]);
          }
        }

      }

      let teams_name = Object.keys(teams);
      for (i = 0; i < teams_name.length; i++) {

        this.$set(this.team_evolution.series, i, {name: teams_name[i], data: teams[[teams_name[i]]]});
      }


      this.team_evolution.chartOptions = {
        ...this.team_evolution.chartOptions, ...{
          labels: label_x,

        }

      }
      this.$forceUpdate()
    },
    sort: function (s) {
      if (s === this.currentSort) {
        this.currentSortDir = this.currentSortDir === 'ascending' ? 'descending' : 'ascending';
      }
      this.currentSort = s;
    },
    changePage(page) {
      this.loadScoreboard(page)
    },
  },
  mounted() {

  },
  computed: {
    teams_sorted: function () {
      let teams = this.teams;
      return teams.sort((a, b) => {
        let modifier = 1;
        if (this.currentSortDir === 'descending') modifier = -1;
        if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
        if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
        return 0;
      });
    }
  },


};
</script>

<template>
  <div>
    <div class="row">
      <div class="col-xl-10 m-auto ">
        <div class="page-title-box d-flex">
          <h3 class="pt-2 text-center">COMPETITION <span class="yellow mx-2"
                                                         @click="$router.push({name:'ProfilCompetition',params:{id:$route.params.id}})">// {{ name }}</span>
          </h3> <span class="gold text-center ml-3">{{ $t('competition.scoreboard') }}</span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-10 m-auto">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title mb-4">{{ $t('profil_comp.top_10') }}</h4>
            <!-- Spline Area chart -->
            <apexchart v-if="load2 && team_evolution.series.length" class="apex-charts"
                       height="350" type="line"
                       dir="ltr" :series="team_evolution.series"
                       :options="team_evolution.chartOptions"></apexchart>
            <span class="text-muted row" v-if="load2 && team_evolution.series.length == 0">
                                    <p class="m-auto">{{ $t('validation.no_data') }}</p>
                                </span>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-10 col-lg-12 col-sm-12 m-auto">
        <div class="card">
          <div class="card-body">
            <h4 class="card-title pt-2">{{ $t('titles.teams') }}</h4>
            <!-- Table -->
            <div v-if="!load1">
              <b-skeleton-table :rows="4" :columns="5"></b-skeleton-table>
              <b-skeleton></b-skeleton>
            </div>

            <div class="row mt-2">

              <table class=" tab table b-table text-center " role="table" v-if="teams.length && load1">
                <thead>
                <tr>
                  <th scope="col" @click="sort('rank')" role="columnheader"
                      :aria-sort="(currentSort == 'rank')?currentSortDir:none">{{ $t('profil_team.rank') }}
                  </th>
                  <th scope="col" @click="sort('team.name')" role="columnheader"
                      :aria-sort="(currentSort == 'team.name')?currentSortDir:none">{{ $t('profil_team.name') }}
                  </th>
                  <th scope="col" @click="sort('score')" role="columnheader"
                      :aria-sort="(currentSort == 'score')?currentSortDir:none">{{ $t('profil_team.score') }}
                  </th>
                  <th scope="col" @click="sort('team.country')" role="columnheader"
                      :aria-sort="(currentSort == 'team.country')?currentSortDir:none">{{ $t('profil_team.country') }}
                  </th>
                  <th scope="col" @click="sort('nbr_of_solves')" role="columnheader"
                      :aria-sort="(currentSort == 'nbr_of_solves')?currentSortDir:none">{{ $t('profil_team.solves') }}
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="data of teams_sorted" :key="data.id">
                  <td data-label="Rank">{{ data.rank }}</td>
                  <td data-label="Name">
                    <router-link class="link h6" :to="{name:'ProfilTeam',params:{id:data.team_id}}">
                      {{ data.team.name }}
                    </router-link>
                  </td>
                  <td data-label="Score"> {{ data.score }}</td>
                  <td data-label="Country"> {{ data.team.country }}</td>
                  <td data-label="Solves">{{ data.solves_count }}</td>
                </tr>
                </tbody>
              </table>
            </div>
            <div class="row mt-3 mb-3">
              <div class="col">
                <div class="text-center">
                  <span class="text-muted" v-if="teams.length ==0 && load1">{{ $t('validation.no_data') }}</span>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>

.yellow {
  color: #ECBD34;
  cursor: pointer;
}

.gold {
  background-color: #ECBD34;
  color: #2A2E51;
  font-size: 14px;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  height: 42px;
}

.yellow:hover {
  color: #b6daf9;
}

</style>